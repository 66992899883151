@import "~blue-react/dist/style";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.App-Input {
  background-color: #2d2f33;
  min-height: 10vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  
}
.App-header {
  background-color: #ffff;
  font-size: calc(10px + 2vmin);
  color: black;
  padding: 4%;
  height: 26cm;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-xml {
  min-height: 0;

  width: 40%;
  font-family: Work Sans;
  color: white;
  background-color: #2980B9;
  border: 0;
  border-radius: 0;
  font-size: larger;
  margin: 0;
}
.btn-xml:hover {
  background-color: #1f618d;
}
.hdr {
  justify-content:right;
  text-align:center;
  background-color: #ffff;
  min-height:28.8cm;
}
.input {
  font-size: large;
  font-family: Work Sans;
  background-color:white;
  color: black;
  border-color: #096EB0;
  border-width:1px;
  width: 40%;
}
.xml-input {
  font-size: large;
  font-family: Work Sans;
  background-color:#201c1c;
  color: white;
  border-color: #096EB0;
  border-width:2px;
 
  width: 60%;
  height: 10cm;
}
.homebtn {
  border: 0;
  background-color: white;
}
.container {
  max-width: 90%;
  margin-right: auto;
  margin-left: auto;
  padding: 1rem;
}
body {
  font-family: "Karla", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.box {
  background-color: #ffff;
  color: white;
  box-shadow: 1px 2px 9px #07507F;
  font-family: Work Sans;
  display: flex;
  padding: 0.5%;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.btn-ans {
  justify-content: center;
}
.btn-blue {
  justify-content: center;
  background-color: #2980B9;
  color: white;
  border-color: white;
  border-width: 0.01cm;
  font-family: Work Sans;

  padding: 0.2%;
  text-decoration: none;
}
.btn-blue:hover {
  background-color: #07507F;
}
.btn-blue:active {
  background-color: #2980B9;
}
.preview {
  width: 80%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #ffff;
  min-height: 5cm;
  padding: 15%;
  margin-top: 2%;
  margin-left: 10%;
  border: #2980B9;
  border-width: 0.15cm;
  border-style:double;
  font-family: Work Sans;
  font-size: x-large;
}
.preview-data {
  justify-content: left;
  display: flex;
  align-items: left;
  margin-top: 0;
  margin-left: 0;
  width: 1;
  font-family: Work Sans;
}
.preview-inner-data {
  justify-content: center;
  font-size: large;
  padding: 0;
}

.back-btn {
  display: flex;
  align-items: left;
  text-align: left;
  justify-content: left;
  float: left;
}
.editor-div {
  border: #07507F;
  border-width: 1px;
  border-style:ridge;
  margin-left: 20%;
  margin-right: 20%;
  height: 10cm;
  overflow: auto;
}
.vs-studio {
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
}
.make-bigger-btn {
  font-family: Work Sans;
  font-size: small;
  background-color: white;
  margin: 1%;
}
